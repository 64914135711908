import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import { ResponsiveIFrame, GamingRelationships, Sidebar, Latex } from "story_components";
import { linearVisData } from "data/gaming-linear-relationships.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`In my book, `}<MDXTag name="em" components={components} parentName="p"><MDXTag name="a" components={components} parentName="em" props={{
            "href": "https://www.amazon.com/Power-Up-Unlocking-Hidden-Mathematics-Video/dp/0691161518/"
          }}>{`Power Up: Unlocking the Hidden Mathematics in Video Games`}</MDXTag>{`,`}</MDXTag>{` I write about mathematical models of relationships inspired by games like `}<MDXTag name="em" components={components} parentName="p">{`The Sims`}</MDXTag>{` and `}<MDXTag name="em" components={components} parentName="p">{`Skyrim`}</MDXTag>{`. I find the models really fun to explore, but there's only so much you can do on the printed page. Here I'd like to provide an overview of what I discuss in the book, along with some more interactive explorations of the models that come up.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The idea of modeling relationships mathematically certainly didn't originate with games. Relationship dynamics have been part of academic research for some time. They've entered popular culture through other mediums, too. One of my favorite examples is this clip from `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Flight_of_the_Conchords"
        }}>{`Flight of the Conchords`}</MDXTag>{`, in which one of the characters, Murray, uses axes to describe his model of friendship.`}</MDXTag>
      <ResponsiveIFrame src="https://www.youtube.com/embed/Vg-zC1xXK3E" heightOverWidth={42 / 56} />
      <MDXTag name="p" components={components}>{`As you can see, Murray's model of friendship is one that consists of a few different states, such as friends, work mates, and enemies. During the course of the episode, he updates his friendship graph in response to the actions of the show's main characters, Brett and Jemaine.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This is very similar to the way the friendship model works in games like `}<MDXTag name="em" components={components} parentName="p">{`The Sims`}</MDXTag>{`. In `}<MDXTag name="em" components={components} parentName="p">{`The Sims 4`}</MDXTag>{`, for example, there are three positive levels of friendship: acquaintance, friend, and good friend. Each one corresponds to a different range of values for their friendship score. You can build the score by interacting positively with other characters in the game.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Mathematically, though, these models don't do a whole lot for me. There's not much here beyond simple arithmetic. Positive interactions add a certain number of points, negative interactions subtract a certain number of points.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Relationship Dynamics: First Model`}</MDXTag>
      <MDXTag name="p" components={components}>{`Things get more interesting when you start to think about one person's feelings changing in response to another person's feelings. Steven Strogatz wrote a `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://ai.stanford.edu/~rajatr/articles/SS_love_dEq.pdf"
        }}>{`one-page treatment`}</MDXTag>{` of this idea in 1988. He begins by imagining two people whose feelings for one another depend entirely on the other person's feelings. In other words, the speed with which person A's feelings for person B are changing at any given point in time depend entirely on how person B is feeling, and vice versa for person A's feelings.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Think about this conceptually for a bit. Suppose that person A's feelings change in opposition to person B: when person B's feelings grow too string, person A begins to push away. But when person B begins to retreat, person A shows more interest. On the other hand, suppose that person B's feelings change in parallel with person A: when person A's feelings are strong, so are person B's, and when they're not, neither is person B's. In this case, if person B has feelings for person A, person A will react by pushing person B away. This, in turn, will cause person B to drift away, which will cause person A to to feel more strongly. This will cause person B to feel more strongly too, which will push person A away again, and the cycle repeats.`}</MDXTag>
      <GamingRelationships visData={linearVisData[0]} caption="Figure 1: Visualizing A and B's feelings based on four inputs." />
      <MDXTag name="p" components={components}>{`For those of you who prefer equations to visualizations, here's what we're visualizing. If `}<MDXTag name="em" components={components} parentName="p">{`A(t)`}</MDXTag>{` denotes person A's feelings at time `}<MDXTag name="em" components={components} parentName="p">{`t`}</MDXTag>{`, and `}<MDXTag name="em" components={components} parentName="p">{`B(t)`}</MDXTag>{` denotes person B's feelings at time `}<MDXTag name="em" components={components} parentName="p">{`t`}</MDXTag>{`, then these graphs are determined by:`}</MDXTag>
      <Sidebar>
  Fun fact: if you've studied differential equations, you can solve these
  equations in terms of trigonometric or exponential functions that you learned
  about in high school.
      </Sidebar>
      <Latex displayMode={true} str={`\\begin{aligned} A^{\\prime} (t) &= a \\times B(t), \\\\ B^{\\prime} (t) &= b \\times A(t), \\end{aligned}`} />
      <MDXTag name="p" components={components}>{`where `}<MDXTag name="em" components={components} parentName="p">{`a`}</MDXTag>{` measures the strength of A's response to B's feelings, and similarly for `}<MDXTag name="em" components={components} parentName="p">{`b`}</MDXTag>{`. If you're not familiar with this notation, don't sweat it: `}<MDXTag name="em" components={components} parentName="p">{`A′(t)`}</MDXTag>{` is the calculus-y way of talking about the `}<MDXTag name="em" components={components} parentName="p">{`speed`}</MDXTag>{` with which A's feelings change. The higher it's value, the more quickly A's feelings are changing.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you play around with this visualization a bit, you may reach a somewhat unsettling conclusion: none of the relationships captured by the model seem particularly healthy. By adjusting the sliders, here are some of the relationships you can model:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`One in which each person's feelings oscillate between positive and negative, with the two parties never really in sync with one another.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`One in which the relationship explodes into an exponentially increasing mutual mania or hatred.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`One in which the relationship explodes in different directions for each person. While one person's feelings become increasingly positive, the other person's feelings become increasingly negative.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`One in which the feelings stabilize, but only at 0, meaning that each person grows indifferent to the other over time.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Put simply, this model of relationships isn't very optimistic. There's no way to model a relationship that naturally grows over time and stabilizes at some reasonable value.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Relationship Dynamics: Second Model`}</MDXTag>
      <MDXTag name="p" components={components}>{`If we want to be able to model a larger class of relationships, we'll need to add more complexity to the model. In the paper I mentioned above, Strogatz offers one possible way to add complexity. Rather than having each person's feelings depend only on the other person's, we should allow them to be a bit more self reflective. In other words, we should allow peoples' feelings to change based on the strength of `}<MDXTag name="em" components={components} parentName="p">{`their`}</MDXTag>{` current feelings, not just the feelings of their partner.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This requires a few more parameters, since we can now adjust three parameters for each person: their initial feelings, the strength with which they respond to their partner's feelings, and the strength with which they respond to their own feelings.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's a modified visualization for you to play around with:`}</MDXTag>
      <GamingRelationships visData={linearVisData[1]} caption="Figure 2: Visualizing A and B's feelings based on six inputs." />
      <MDXTag name="p" components={components}>{`As you might have guessed, more sliders means more parameters in our model. In the language of calculus, our model now looks like this:`}</MDXTag>
      <Sidebar direction="right">
  These equations can also be solved in terms of functions you may have
  encountered in a high school math class!
      </Sidebar>
      <Latex displayMode={true} str={`\\begin{aligned} A^{\\prime} (t) &= a \\times B(t) + c \\times A(t), \\\\ B^{\\prime} (t) &= b \\times A(t) + d \\times B(t), \\end{aligned}`} />
      <MDXTag name="p" components={components}>{`Once again, if your calculus is a little rusty and you can feel your eyes glazing over, fret not! All this is saying is that the rate at which each person's feelings change depends not only on their partner's current feelings, but their own feelings as well. The sliders let us control the values of `}<MDXTag name="em" components={components} parentName="p">{`a`}</MDXTag>{`, `}<MDXTag name="em" components={components} parentName="p">{`b`}</MDXTag>{`, `}<MDXTag name="em" components={components} parentName="p">{`c`}</MDXTag>{`, and `}<MDXTag name="em" components={components} parentName="p">{`d`}</MDXTag>{` (as well as each partner's `}<MDXTag name="em" components={components} parentName="p">{`initial`}</MDXTag>{` feelings), so that you can explore a wider range of possible relationships.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In fact, all of these options for values yield four different archetypes, depending on how people respond to the feelings of themselves and their partners. In his book `}<MDXTag name="em" components={components} parentName="p">{`Nonlinear Dynamics and Chaos`}</MDXTag>{`, Strogatz identifies four categories of people. For example, depending on the values of `}<MDXTag name="em" components={components} parentName="p">{`a`}</MDXTag>{` and `}<MDXTag name="em" components={components} parentName="p">{`c`}</MDXTag>{`, person A could fit into one of these groups:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="em" components={components} parentName="li">{`Eager beavers`}</MDXTag>{`. (`}<MDXTag name="em" components={components} parentName="li">{`a`}</MDXTag>{` > 0, `}<MDXTag name="em" components={components} parentName="li">{`c`}</MDXTag>{` > 0.) These people respond positively to positive feelings in themselves and their partners.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="em" components={components} parentName="li">{`Narcissistic Nerds`}</MDXTag>{`. (`}<MDXTag name="em" components={components} parentName="li">{`a`}</MDXTag>{` < 0, `}<MDXTag name="em" components={components} parentName="li">{`c`}</MDXTag>{` > 0.) These people respond positively to their own positive feelings, but are turned off by positive feelings in their partners.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="em" components={components} parentName="li">{`Cautious lovers`}</MDXTag>{`. (`}<MDXTag name="em" components={components} parentName="li">{`a`}</MDXTag>{` > 0, `}<MDXTag name="em" components={components} parentName="li">{`c`}</MDXTag>{` < 0.) These people respond positively to positive feelings in their partners, but are distrustful of their own positive feelings.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol"><MDXTag name="em" components={components} parentName="li">{`Hermits`}</MDXTag>{`. (`}<MDXTag name="em" components={components} parentName="li">{`a`}</MDXTag>{` < 0, `}<MDXTag name="em" components={components} parentName="li">{`c`}</MDXTag>{` < 0.) These people respond negatively to all positive feelings.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`With these archetypes in mind, play around with the above visualization and see what sorts of patterns emerge. What happens when two narcissists get together? What abou an eager beaver and a hermit?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Unfortunately, you'll soon discover that in terms of stable healthy relationships, this model doesn't really do any better than the previous one. In fact, in some sense it's even more pessimistic: it can lead to some really horrendous looking relationships, including ones that both oscillate and grow exponentially!`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Relationship Dynamics: Third Model`}</MDXTag>
      <MDXTag name="p" components={components}>{`In our quest for a stable, healthy relationship, we keep coming up short. For all of the sliders we can adjust in our visualizations, maybe our models are still too simple.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Fortunately, if you study up on the theory of differential equations, you'll eventually learn that we're not too far off. In fact, there are theorems that specify when systems like the ones we've explored have stable solutions. `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Matrix_differential_equation"
        }}>{`Wikipedia`}</MDXTag>{` has a primer, but watch out - terse mathematical notation abounds!`}</MDXTag>
      <MDXTag name="p" components={components}>{`As it turns out, we can produce a larger class of stable solutions by accounting for each partner's intrinsic appeal. The terminology here is intentionally vague - if you're curious how this corresponds to actual math, you can check out the equations below. For now, all you need to know is that we can expand the model by asserting that each person in the relationship has some intrinsic, quantifiable level of appeal, and that each person in the relationship responds not only to their own feelings and their partner's feelings, but also to their partner's intrinsic appeal. Such a model is explored in more detail in a 1998 paper by Sergio Rinaldi.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This brings us to our final final visualization, which will let you play around with these new values. To simplify things somewhat, I've removed the sliders that allow you to adjust each person's initial feelings. Instead, each person's initial feelings are assumed to be zero. In other words, these models assume that people who haven't met won't have feelings for each other, which seems reasonable.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's one example of a relationship that stabilizes. What other ones can you find?`}</MDXTag>
      <GamingRelationships visData={linearVisData[2]} caption="Figure 3: Visualizing A and B's feelings based on eight inputs." />
      <MDXTag name="p" components={components}>{`Once again, here are the equations, if you're into that sort of thing:`}</MDXTag>
      <Latex displayMode={true} str={`\\begin{aligned} A^{\\prime} (t) &= a \\times B(t) + c \\times A(t) + e \\times B_{appeal}, \\\\ B^{\\prime} (t) &= b \\times A(t) + d \\times B(t) + f \\times A_{appeal}, \\end{aligned}`} />
      <MDXTag name="p" components={components}>{`The sliders let us control the values of the eight parameters in the above equations.`}</MDXTag>
      <MDXTag name="p" components={components}>{`There are some fun facts to note here:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`In Rinaldi's initial paper, he imposes a restriction that `}<MDXTag name="em" components={components} parentName="li">{`c`}</MDXTag>{` and `}<MDXTag name="em" components={components} parentName="li">{`d`}</MDXTag>{` are negative, and the rest of the values are positive. If you follow this restriction, you should be able to find quite a few examples of stable relationships. Something to ponder: why might these assumptions make sense?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`While the assumptions in the last point open the door to stable relationships, they aren't sufficient for finding stable relationships. However, given the assumption on `}<MDXTag name="em" components={components} parentName="li">{`c`}</MDXTag>{` and `}<MDXTag name="em" components={components} parentName="li">{`d`}</MDXTag>{`, the condition for stability is actually pretty simple: we just need `}<MDXTag name="em" components={components} parentName="li">{`ab`}</MDXTag>{` < -`}<MDXTag name="em" components={components} parentName="li">{`cd`}</MDXTag>{`.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`If you adjust the values of the sliders so that `}<MDXTag name="em" components={components} parentName="li">{`ab`}</MDXTag>{` is close to (but still less than) -`}<MDXTag name="em" components={components} parentName="li">{`cd`}</MDXTag>{`, you may notice another interesting phenomenon: the longer it takes for people to reach their stable values, the higher those values. In other words, the slower the burn, the more satisfying the relationship over time.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`In a scenario where the feelings stabilize, if you increase one person's intrinsic appeal, both parties' stable values increase. However, the `}<MDXTag name="em" components={components} parentName="li">{`other`}</MDXTag>{` person's stable value increases more. Or, as Rinaldi puts it, "there is a touch of altruism in a woman (man) who tries to improve her (his) appeal."`}</MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="p" components={components}>{`Of course, even though we can now model an even larger class of relationships, there's still room for improvement. We can generate relationships that stabilize, sure, but the trajectories of these relationships don't seem the most realistic. When in your life have you ever met someone for whom your feelings grew without bound, without any bumps in the road or challenges along the way? Unless you are my wife, the answer is probably never.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We can get some even stranger looking relationship models. But to do so, we'll need to explore an entirely different set of equations. So I'll save such an exploration for next time.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sources:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.amazon.com/Power-Up-Unlocking-Hidden-Mathematics-Video/dp/0691161518/"
          }}>{`Power Up: Unlocking the Hidden Mathematics in Video Games`}</MDXTag>{`, by me!`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://ai.stanford.edu/~rajatr/articles/SS_love_dEq.pdf"
          }}>{`Love Affairs and Differential Equations`}</MDXTag>{`, by Steven Strogatz`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.amazon.com/Nonlinear-Dynamics-Chaos-Applications-Nonlinearity/dp/0738204536"
          }}>{`Nonlinear Dynamics And Chaos`}</MDXTag>{`, by Steven Strogatz`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://en.wikipedia.org/wiki/Matrix_differential_equation"
          }}>{`Introduction to Matrix Differential Equations`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://www.sciencedirect.com/science/article/pii/S0096300397100819"
          }}>{`Love Dynamics: The Case of Linear Couples`}</MDXTag>{`, by Sergio Rinaldi`}</MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "Gaming Relationships: Linear Approaches",
  "date": "2017-11-22",
  "featured_image": "../../images/featured_images/murray.jpg",
  "caption": "A mathematical ballad of love and hate.",
  "featured_image_caption": "Murray explaining his relationship model. Image credit: Flight of the Conchords (HBO).",
  "tags": ["differential equations", "relationships", "modeling", "functions"]
};
      